import { h, ComponentType } from 'preact'
import Welcome from '../Welcome'
import UserConsent from '../UserConsent'

import ImageQualityGuide from '../Uploader/ImageQualityGuide'
import SelfieIntro from '../Photo/SelfieIntro'
import {
  DocumentBackCapture,
  DocumentFrontCapture,
  DataCapture,
  FaceVideoCapture,
  PoaFrontCapture,
  PoaBackCapture,
  SelfieCapture,
  FaceMotionCapture,
} from '../Capture'
import {
  DocumentBackConfirm,
  DocumentFrontConfirm,
  FaceVideoConfirm,
  PoABackConfirm,
  PoAFrontConfirm,
  SelfieConfirm,
} from '../Confirm'
import Complete from '../Complete'
import Retry from '~workflow-engine/Retry'
import MobileFlow from '../crossDevice/MobileFlow'
import CrossDeviceLink from '../crossDevice/CrossDeviceLink'
import CrossDeviceClientIntro from 'components/crossDevice/ClientIntro'
import ClientSuccess from '../crossDevice/ClientSuccess'
import CrossDeviceIntro from '../crossDevice/Intro'
import FaceVideoIntro from '../FaceVideo/Intro'
import {
  LazyActiveVideoOldPermissionsFlow,
  LazyActiveVideoNewPermissionsFlow,
} from '../ActiveVideo/Lazy'
import ActiveVideoIntro from '../ActiveVideo/Intro'
import ActiveVideoConfirm from '../ActiveVideo/Confirm'
import FaceMotionIntro from '../FaceMotion/Intro'
import FaceMotionConfirm from '../FaceMotion/Confirm'
import WorkflowFallback from '../FaceMotion/WorkflowFallback'
import Loading from '../ActiveVideo/Loading'
import { shouldUseCameraForDocumentCapture } from '~utils/shouldUseCamera'
import { buildStepFinder } from '~utils/steps'
import type {
  ExtendedStepConfig,
  ExtendedStepTypes,
  FlowVariants,
} from '~types/commons'
import type { PoASupportedCountry, PoaDocumentTypes } from '~types/api'
import type { StepComponentProps, ComponentStep } from '~types/routers'
import type {
  DocumentTypes,
  StepConfig,
  StepConfigActiveVideo,
  StepConfigDocument,
  StepConfigFace,
  StepConfigData,
  StepTypes,
  OptionsEnabled,
  Consents,
  GenericDocumentType,
  PoaTypes,
} from '~types/steps'
import PoAClientIntro from '../ProofOfAddress/PoAIntro'
import Guidance from '../ProofOfAddress/Guidance'
import PoADocumentSelector from '../DocumentSelector/PoADocumentSelector'
import PoACountrySelector from '../CountrySelector/PoACountrySelector'
import { RestrictedDocumentSelection } from '../RestrictedDocumentSelection'
import { preloadModels } from 'components/ActiveVideo/utils'
import { ProfileDataProps } from 'components/Capture/ProfileData'
import { isDoubleSidedDocument } from '~utils/Documents'
import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { showRestrictedDocumentSelectionStep } from 'components/RestrictedDocumentSelection/showRestrictedDocumentSelectionStep'
import { hasIdNumber } from 'components/Capture/IdNumberTypes'
import { sendEvent } from 'Tracker'

const sortedProfileDataCaptureFields = [
  'first_name',
  'last_name',
  'dob',
  'email',
  'phone_number',
  'nationality',
  'ssn',
  'pan',
]

type ComponentsByStepType = Partial<
  Record<ExtendedStepTypes, ComponentType<StepComponentProps>[]>
>

export type ComponentsListProps = {
  flow: FlowVariants
  documentType: DocumentTypes | undefined
  genericDocumentType: GenericDocumentType | undefined
  poaDocumentCountry?: PoASupportedCountry | undefined
  steps: StepConfig[]
  mobileFlow?: boolean
  deviceHasCameraSupport?: boolean
  deviceCanUseMotion?: boolean | undefined
  deviceCantUseMotionReason?: string | undefined
  hasPreviousStep?: boolean
  sdkConfiguration: SdkConfiguration
  isFirstCrossDeviceStep?: boolean
  poaDocumentType?: PoaTypes
}

export const buildComponentsList = ({
  flow,
  documentType,
  genericDocumentType,
  steps,
  mobileFlow,
  deviceHasCameraSupport,
  deviceCanUseMotion,
  deviceCantUseMotionReason,
  poaDocumentCountry,
  hasPreviousStep,
  sdkConfiguration,
  isFirstCrossDeviceStep,
  poaDocumentType,
}: ComponentsListProps): ComponentStep[] => {
  return flow === 'captureSteps'
    ? buildComponentsFromSteps(
        buildCaptureStepComponents(
          poaDocumentCountry,
          documentType,
          genericDocumentType,
          mobileFlow,
          steps,
          sdkConfiguration,
          deviceHasCameraSupport,
          deviceCanUseMotion,
          deviceCantUseMotionReason,
          hasPreviousStep,
          isFirstCrossDeviceStep,
          poaDocumentType
        ),
        steps
      )
    : buildComponentsFromSteps(
        crossDeviceDesktopComponents,
        crossDeviceSteps(steps)
      )
}

// Submit the fallback event only once for multiple component renderings.
let fallbackEventSent = false

const isComplete = (step: StepConfig): boolean => step.type === 'complete'

const hasCompleteStep = (steps: StepConfig[]): boolean => steps.some(isComplete)

const buildCaptureStepComponents = (
  poaDocumentCountry: PoASupportedCountry | undefined,
  documentType: DocumentTypes | undefined,
  genericDocumentType: GenericDocumentType | undefined,
  mobileFlow: boolean | undefined,
  steps: StepConfig[],
  sdkConfiguration: SdkConfiguration,
  deviceHasCameraSupport?: boolean,
  deviceCanUseMotion?: boolean | undefined,
  deviceCantUseMotionReason?: string | undefined,
  hasPreviousStep?: boolean,
  isFirstCrossDeviceStep?: boolean,
  poaDocumentType?: PoaTypes | undefined
): ComponentsByStepType => {
  const findStep = buildStepFinder(steps)
  const faceStep = findStep('face')
  const documentStep = findStep('document')
  const dataStep = findStep('data')
  const activeVideoStep = findStep('activeVideo')
  const complete = mobileFlow
    ? [ClientSuccess as ComponentType<StepComponentProps>]
    : [Complete]
  const captureStepTypes = new Set<StepTypes>([
    'document',
    'poa',
    'face',
    'data',
    'activeVideo',
  ])
  const firstCaptureStepType = steps.filter((step) =>
    captureStepTypes.has(step?.type)
  )[0]?.type

  return {
    welcome: [Welcome],
    userConsent: [UserConsent],
    ...(faceStep && {
      face: [
        ...buildFaceComponents(
          faceStep,
          deviceHasCameraSupport,
          deviceCanUseMotion,
          deviceCantUseMotionReason,
          mobileFlow,
          !hasPreviousStep && mobileFlow && firstCaptureStepType === 'face'
        ),
      ],
    }),
    ...(activeVideoStep && {
      activeVideo: [
        ...buildActiveVideoComponents(
          !!sdkConfiguration.sdk_features?.web_disable_new_permissions_flow,
          !!mobileFlow,
          !hasPreviousStep &&
            mobileFlow &&
            firstCaptureStepType === 'activeVideo'
        ),
      ],
    }),
    document: [
      ...buildDocumentComponents(
        documentStep,
        documentType,
        genericDocumentType,
        shouldUseCameraForDocumentCapture(
          sdkConfiguration,
          deviceHasCameraSupport
        ),
        mobileFlow,
        !hasPreviousStep && firstCaptureStepType === 'document',
        isFirstCrossDeviceStep
      ),
    ],
    data: [...buildDataComponents(dataStep)],
    poa: [
      ...buildPoaComponents(
        // @ts-ignore
        poaDocumentCountry,
        poaDocumentType,
        mobileFlow,
        !hasPreviousStep && firstCaptureStepType === 'poa'
      ),
    ],
    complete,
    retry: [Retry],
  }
}

const buildDataComponents = (
  dataStep?: StepConfigData
): ComponentType<StepComponentProps>[] => {
  if (!dataStep?.options?.profile_data_selection) {
    return []
  }

  const CountryOfResidence = (props: ProfileDataProps) => (
    <DataCapture
      {...props}
      title="country_of_residence_title"
      dataFields={['country_residence']}
      getPersonalData={dataStep?.options?.getPersonalData}
    />
  )

  const profileDataCaptureFields = dataStep?.options?.profile_data_selection
    ? sortedProfileDataCaptureFields.filter((key) => {
        const optionKey = `${key}_enabled` as OptionsEnabled
        return (
          dataStep?.options &&
          dataStep?.options?.profile_data_selection &&
          Boolean(dataStep?.options?.profile_data_selection[optionKey]) === true
        )
      })
    : ['first_name', 'last_name', 'dob', 'ssn']

  const hasCountryOfResidenceEnabled =
    dataStep?.options &&
    dataStep?.options?.profile_data_selection &&
    Boolean(
      dataStep?.options?.profile_data_selection['country_residence_enabled']
    )

  const { profile_data_selection, input } = dataStep.options
  const profileDataSelectionKeys = Object.keys(profile_data_selection) as Array<
    keyof typeof profile_data_selection
  >

  const consents = profileDataSelectionKeys
    .filter((key) => profile_data_selection[key])
    .filter((key) => key.endsWith('_consent_required'))
    .map((key) => key.replace('_required', '') as Consents)
    .filter((key) => !input?.[`${key}_granted`])

  const PersonalInformation = (props: ProfileDataProps) => (
    <DataCapture
      {...props}
      title="personal_information_title"
      dataFields={profileDataCaptureFields}
      ssnEnabled={
        dataStep?.options?.ssn_enabled ||
        dataStep?.options?.profile_data_selection?.ssn_enabled
      }
      panEnabled={dataStep?.options?.profile_data_selection?.pan_enabled}
      getPersonalData={dataStep?.options?.getPersonalData}
      consents={consents}
    />
  )
  const NationalIdNumber = (props: ProfileDataProps) => (
    <DataCapture
      {...props}
      title="national_id_number_title"
      dataSubPath="national_id_number"
      dataFields={['national_id_type', 'national_id_value']}
      panEnabled={dataStep?.options?.profile_data_selection?.pan_enabled}
      nationalIdNumberEnabled={
        dataStep?.options?.profile_data_selection?.national_id_number_enabled
      }
      getPersonalData={dataStep?.options?.getPersonalData}
    />
  )
  const selectedCountry = () =>
    (dataStep?.options?.getPersonalData?.() as {
      address: { country: string }
    })?.address?.country

  const enableNationalIdNumber = (country: string) => {
    return (
      country &&
      hasIdNumber(country) &&
      !(
        country === 'IND' &&
        dataStep?.options?.profile_data_selection?.pan_enabled
      )
    )
  }

  const Address = (props: ProfileDataProps) => (
    <DataCapture
      {...props}
      title="address_title"
      dataSubPath="address"
      dataFields={[
        'country',
        'line1',
        'line2',
        'line3',
        'town',
        'state',
        'postcode',
      ]}
      disabledFields={hasCountryOfResidenceEnabled ? ['country'] : []}
      getPersonalData={dataStep?.options?.getPersonalData}
    />
  )

  const dataComponents = []

  if (
    Boolean(
      dataStep?.options?.profile_data_selection?.country_residence_enabled
    ) ||
    dataStep?.options?.profile_data_selection?.country_residence_enabled ===
      undefined
  )
    dataComponents.push(CountryOfResidence)

  dataComponents.push(PersonalInformation)

  if (
    dataStep?.options?.profile_data_selection?.national_id_number_enabled &&
    enableNationalIdNumber(selectedCountry())
  )
    dataComponents.push(NationalIdNumber)

  if (
    Boolean(dataStep?.options?.profile_data_selection?.address_enabled) ||
    dataStep?.options?.profile_data_selection?.country_residence_enabled ===
      undefined
  )
    dataComponents.push(Address)

  return dataComponents as ComponentType<StepComponentProps>[]
}

const buildFaceComponents = (
  faceStep?: StepConfigFace | StepConfigActiveVideo,
  deviceHasCameraSupport?: boolean,
  deviceCanUseMotion?: boolean | undefined,
  deviceCantUseMotionReason?: string | undefined,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean | undefined
): ComponentType<StepComponentProps>[] => {
  const {
    requestedVariant,
    useWorkflow,
    useUploader,
    motionFallbackVariant,
    photoCaptureFallback,
    showIntro,
  } = faceStep?.options ?? {}

  // if shouldDisplayUploader is true webcam should not be used
  const shouldSelfieScreenUseCamera = !useUploader && deviceHasCameraSupport
  const canRecordVideo = window.MediaRecorder != null && deviceHasCameraSupport
  const requestedMotion = requestedVariant === 'motion'
  const requestedVideo = requestedVariant === 'video'
  const avc_unsupported_reason = deviceCantUseMotionReason

  const shouldUseMotion =
    requestedMotion && deviceCanUseMotion && canRecordVideo

  const shouldUseVideo =
    (requestedVideo ||
      (requestedMotion && motionFallbackVariant === 'video')) &&
    (canRecordVideo || photoCaptureFallback === false)

  const fallbackMotionOnWorkflow =
    useWorkflow &&
    requestedMotion &&
    (deviceCanUseMotion === false || !canRecordVideo)

  if (fallbackMotionOnWorkflow) {
    if (!fallbackEventSent) {
      fallbackEventSent = true
      const avc_integration = 'studio'
      sendEvent('screen_face_face_motion_unsupported', {
        avc_integration,
        avc_unsupported_reason,
      })
    }

    return buildMotionWorkflowFallbackComponents(
      canRecordVideo,
      mobileFlow,
      isFirstCaptureStepInFlow
    )
  }

  if (requestedMotion && shouldUseMotion === false && !fallbackEventSent) {
    fallbackEventSent = true

    if (motionFallbackVariant) {
      const avc_fallback_variant =
        motionFallbackVariant === 'standard' ? 'selfie' : motionFallbackVariant

      sendEvent('screen_face_face_motion_fallback', {
        avc_fallback_variant,
        avc_unsupported_reason,
      })
    } else {
      const avc_integration = 'classic'
      sendEvent('screen_face_face_motion_unsupported', {
        avc_integration,
        avc_unsupported_reason,
      })
    }
  }

  if (shouldUseMotion === undefined) {
    return [Loading]
  }

  if (shouldUseMotion) {
    return buildRequiredMotionComponents(
      canRecordVideo,
      mobileFlow,
      isFirstCaptureStepInFlow
    )
  }

  if (shouldUseVideo) {
    return buildRequiredVideoComponents(
      canRecordVideo,
      mobileFlow,
      isFirstCaptureStepInFlow
    )
  }

  return buildRequiredSelfieComponents(
    showIntro,
    shouldSelfieScreenUseCamera,
    mobileFlow,
    isFirstCaptureStepInFlow
  )
}

const buildActiveVideoComponents = (
  oldPermissions: boolean,
  shouldUseCamera?: boolean,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean
): ComponentType<StepComponentProps>[] => {
  // Active Video Capture SDK already includes a footer and should appear under a
  // semi-transparent navigation bar. There is no way to set it at the step level
  // because it only impacts the capture screen. As a (horrible) workaround,
  // `StepsRouter` will look at the component name to set `edgeToEdgeContent`.
  const ActiveVideoCapture = oldPermissions
    ? LazyActiveVideoOldPermissionsFlow
    : LazyActiveVideoNewPermissionsFlow
  ActiveVideoCapture.displayName = 'ActiveVideoCapture'

  const allActiveVideoSteps: ComponentType<StepComponentProps>[] = [
    ActiveVideoIntro,
    ActiveVideoCapture,
    ActiveVideoConfirm,
  ]

  if (mobileFlow && !shouldUseCamera) {
    // do not display intro on cross device flow
    return allActiveVideoSteps.slice(1)
  }

  return mobileFlow && isFirstCaptureStepInFlow
    ? buildCrossDeviceClientComponents(allActiveVideoSteps)
    : allActiveVideoSteps
}

const buildMotionWorkflowFallbackComponents = (
  shouldUseCamera?: boolean,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean
): ComponentType<StepComponentProps>[] => {
  const allMotionSteps: ComponentType<StepComponentProps>[] = [WorkflowFallback]

  preloadModels()

  if (mobileFlow && !shouldUseCamera) {
    // do not display intro on cross device flow
    return allMotionSteps.slice(1)
  }

  return mobileFlow && isFirstCaptureStepInFlow
    ? buildCrossDeviceClientComponents(allMotionSteps)
    : allMotionSteps
}

const buildRequiredMotionComponents = (
  shouldUseCamera?: boolean,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean
): ComponentType<StepComponentProps>[] => {
  const allMotionSteps: ComponentType<StepComponentProps>[] = [
    FaceMotionIntro,
    FaceMotionCapture,
    FaceMotionConfirm,
  ]

  preloadModels()

  if (mobileFlow && !shouldUseCamera) {
    // do not display intro on cross device flow
    return allMotionSteps.slice(1)
  }

  return mobileFlow && isFirstCaptureStepInFlow
    ? buildCrossDeviceClientComponents(allMotionSteps)
    : allMotionSteps
}

const buildRequiredVideoComponents = (
  shouldUseCamera?: boolean,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean
): ComponentType<StepComponentProps>[] => {
  const allVideoSteps: ComponentType<StepComponentProps>[] = [
    FaceVideoIntro,
    FaceVideoCapture,
    FaceVideoConfirm,
  ]

  if (mobileFlow && !shouldUseCamera) {
    // do not display intro on cross device flow
    return allVideoSteps.slice(1)
  }

  return mobileFlow && isFirstCaptureStepInFlow
    ? buildCrossDeviceClientComponents(allVideoSteps)
    : allVideoSteps
}

const buildRequiredSelfieComponents = (
  showIntro = true,
  deviceHasCameraSupport?: boolean,
  mobileFlow?: boolean,
  isFirstCaptureStepInFlow?: boolean
): ComponentType<StepComponentProps>[] => {
  const allSelfieSteps: ComponentType<StepComponentProps>[] = [
    SelfieIntro,
    SelfieCapture,
    SelfieConfirm,
  ]

  if (!deviceHasCameraSupport || !showIntro) {
    // do not display intro if camera cannot be used or specifically excluded
    return allSelfieSteps.slice(1)
  }

  return mobileFlow && isFirstCaptureStepInFlow
    ? buildCrossDeviceClientComponents(allSelfieSteps)
    : allSelfieSteps
}

const buildDocumentComponents = (
  documentStep: StepConfigDocument | undefined,
  documentType: DocumentTypes | undefined,
  genericDocumentType: GenericDocumentType | undefined,
  shouldUseCamera: boolean,
  mobileFlow: boolean | undefined,
  isFirstCaptureStepInFlow: boolean | undefined,
  isFirstCrossDeviceStep: boolean | undefined
): ComponentType<StepComponentProps>[] => {
  const options = documentStep?.options

  const isPassportDocument = documentType === 'passport'

  if (isPassportDocument) {
    const preCaptureComponents = showRestrictedDocumentSelectionStep(options)
      ? [RestrictedDocumentSelection]
      : []

    const standardCaptureComponents = shouldUseCamera
      ? [DocumentFrontCapture, DocumentFrontConfirm]
      : [DocumentFrontCapture, ImageQualityGuide, DocumentFrontConfirm]

    return buildFrontCaptureComponents(
      mobileFlow,
      isFirstCaptureStepInFlow,
      documentType,
      preCaptureComponents,
      standardCaptureComponents,
      isFirstCrossDeviceStep
    )
  }

  const preCaptureComponents = showRestrictedDocumentSelectionStep(options)
    ? [RestrictedDocumentSelection]
    : []

  const frontCaptureComponents = [DocumentFrontCapture, DocumentFrontConfirm]

  const requiredFrontCaptureComponents = buildFrontCaptureComponents(
    mobileFlow,
    isFirstCaptureStepInFlow,
    documentType,
    preCaptureComponents,
    frontCaptureComponents,
    isFirstCrossDeviceStep
  )

  if (isDoubleSidedDocument(documentType, genericDocumentType)) {
    return [
      ...requiredFrontCaptureComponents,
      DocumentBackCapture,
      DocumentBackConfirm,
    ]
  }

  return requiredFrontCaptureComponents
}

const buildFrontCaptureComponents = (
  mobileFlow: boolean | undefined,
  isFirstCaptureStepInFlow: boolean | undefined,
  documentType: DocumentTypes | undefined,
  preCaptureComponents: ComponentType<StepComponentProps>[],
  frontCaptureComponents: ComponentType<StepComponentProps>[],
  isFirstCrossDeviceStep: boolean | undefined
): ComponentType<StepComponentProps>[] => {
  const defaultFrontCapture = [
    ...preCaptureComponents,
    ...frontCaptureComponents,
  ]

  if (!mobileFlow) {
    return defaultFrontCapture
  }

  if (isFirstCaptureStepInFlow) {
    return [...buildCrossDeviceClientComponents(frontCaptureComponents)]
  } else if (documentType) {
    // document type and document is the first step in the flow is already defined - don't want to see again the restricted document selection
    // document type is define but we are not the first step in the flow , so either 2 document capture steps or there has been another capture
    // step there we need to go through default steps also adds the intro
    return [
      ...(isFirstCrossDeviceStep
        ? buildCrossDeviceClientComponents(frontCaptureComponents)
        : defaultFrontCapture),
    ]
  }
  // mobile, not the first step, and no document type defined
  return defaultFrontCapture
}

const buildPoaComponents = (
  poaDocumentCountry: PoASupportedCountry | undefined,
  poaDocumentType: PoaTypes | undefined,
  mobileFlow: boolean | undefined,
  isFirstCaptureStepInFlow: boolean | undefined
): ComponentType<StepComponentProps>[] => {
  const preCaptureComponents: ComponentType<StepComponentProps>[] = [
    PoAClientIntro,
    PoACountrySelector,
    PoADocumentSelector,
    Guidance,
  ]

  const captureComponents =
    poaDocumentCountry &&
    poaDocumentType &&
    // @ts-ignore
    poaDocumentCountry.document_types[poaDocumentType] &&
    // @ts-ignore
    poaDocumentCountry.document_types[poaDocumentType].requires_back
      ? [PoaFrontCapture, PoAFrontConfirm, PoaBackCapture, PoABackConfirm]
      : [PoaFrontCapture, PoAFrontConfirm]

  return mobileFlow && isFirstCaptureStepInFlow
    ? [...buildCrossDeviceClientComponents(captureComponents)]
    : [...preCaptureComponents, ...captureComponents]
}

const crossDeviceSteps = (steps: StepConfig[]): ExtendedStepConfig[] => {
  const baseSteps: ExtendedStepConfig[] = [{ type: 'crossDevice' }]
  const completeStep = steps.find(isComplete) as ExtendedStepConfig
  return hasCompleteStep(steps) ? [...baseSteps, completeStep] : baseSteps
}

const crossDeviceDesktopComponents: ComponentsByStepType = {
  crossDevice: [CrossDeviceIntro, CrossDeviceLink, MobileFlow],
  complete: [Complete],
}

const buildCrossDeviceClientComponents = (
  captureComponents: ComponentType<StepComponentProps>[]
): ComponentType<StepComponentProps>[] => {
  return [CrossDeviceClientIntro, ...captureComponents]
}

const buildComponentsFromSteps = (
  components: ComponentsByStepType,
  steps: ExtendedStepConfig[]
): ComponentStep[] => {
  const builtSteps = steps.map((step, stepIndex) =>
    createComponent(components, step, stepIndex)
  )
  return ([] as ComponentStep[]).concat(...builtSteps)
}

const createComponent = (
  components: ComponentsByStepType,
  step: ExtendedStepConfig,
  stepIndex: number
): ComponentStep[] => {
  const { type } = step
  const componentsByStep = components[type]

  if (!componentsByStep) {
    console.error(`No such step: ${type}`)
    return []
  }

  return componentsByStep.map(wrapComponent(step, stepIndex))
}

const wrapComponent = (step: ExtendedStepConfig, stepIndex: number) => (
  component: ComponentType<StepComponentProps>
): ComponentStep => ({
  component,
  step,
  stepIndex,
})
