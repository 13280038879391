import { FunctionComponent, h } from 'preact'
import { Button } from '@onfido/castor-react'
import classNames from 'classnames'
import { localised } from '~core/localisation'
import PageTitle from 'components/PageTitle'
import ScreenLayout from 'components/Theme/ScreenLayout'
import theme from 'components/Theme/style.scss'
import style from './style.scss'

import type { WithLocalisedProps } from '~core/localisation/types'
import { NumberedBulletList } from './NumberedBulletPoints'
import { Disclaimer } from 'components/Disclaimer'
import { useEffect, useLayoutEffect } from 'preact/hooks'
import { getBrowserSettings, getDeviceSettings } from './getSolutionSteps'
import { CameraPermissions } from '../utils'
import { useRef, useState } from 'react'

type RecoverProps = {
  onClick: () => void
  onInit: () => void
  missingPermissions: CameraPermissions
}

type Props = RecoverProps & WithLocalisedProps

const Recover: FunctionComponent<Props> = ({
  missingPermissions,
  onClick,
  onInit,
  translate,
}) => {
  useEffect(() => {
    onInit()
  }, [])

  const scrollableRef = useRef<HTMLDivElement | null>(null)
  const [displayGradientEffect, setDisplayGradientEffect] = useState(false)

  const onScroll = () => {
    if (!scrollableRef.current) {
      return
    }
    //https://stackoverflow.com/a/34550171
    const hasScrolledToBottom =
      Math.abs(
        scrollableRef.current?.scrollHeight -
          scrollableRef.current?.scrollTop -
          scrollableRef.current?.clientHeight
      ) < 1

    setDisplayGradientEffect(!hasScrolledToBottom)
  }

  useLayoutEffect(() => {
    if (!scrollableRef.current) {
      return
    }
    const isOverflowing =
      scrollableRef.current.clientHeight < scrollableRef.current.scrollHeight

    setDisplayGradientEffect(isOverflowing)

    if (isOverflowing) {
      scrollableRef.current.onscroll = onScroll
    }
  }, [scrollableRef, setDisplayGradientEffect])

  const locales = {
    camera: {
      title: 'permission_recovery.title_cam',
      subtitle: 'permission_recovery.subtitle_cam',
    },
    both: {
      title: 'permission_recovery.title_both',
      subtitle: 'permission_recovery.subtitle_both',
    },
    microphone: {
      title: 'permission_recovery.title_mic',
      subtitle: 'permission_recovery.subtitle_mic',
    },
    solution1: 'permission_recovery.web.browser_settings.title',
    solution1_steps: getBrowserSettings(
      missingPermissions.video,
      missingPermissions.audio,
      translate
    ),
    solution2: 'permission_recovery.web.device_settings.title',
    solution2_steps: getDeviceSettings(
      missingPermissions.video,
      missingPermissions.audio,
      translate
    ),
    disclaimer: 'permission_recovery.web.disclaimer',
    refresh_button: 'permission_recovery.web.button_primary',
  }

  const actions = (
    <Button
      variant="primary"
      className={classNames(theme['button-centered'], theme['button-lg'])}
      onClick={onClick}
    >
      {translate(locales.refresh_button)}
    </Button>
  )

  let title = ''
  let subtitle = ''
  if (missingPermissions.audio && missingPermissions.video) {
    title = locales.both.title
    subtitle = locales.both.subtitle
  } else if (missingPermissions.audio) {
    title = locales.microphone.title
    subtitle = locales.microphone.subtitle
  } else {
    title = locales.camera.title
    subtitle = locales.camera.subtitle
  }

  return (
    <ScreenLayout
      className={theme.fullHeightContainer}
      actions={actions}
      ref={scrollableRef}
      addOverflowGradient={displayGradientEffect}
    >
      <PageTitle title={translate(title)} subTitle={translate(subtitle)} />
      <div className={style.instructions}>
        <p className={style.instructionsTitle}>
          {translate(locales.solution1)}
        </p>
        <NumberedBulletList items={locales.solution1_steps} />
      </div>
      <div className={style.instructions}>
        <p className={style.instructionsTitle}>
          {translate(locales.solution2)}
        </p>
        <NumberedBulletList items={locales.solution2_steps} />
      </div>
      <Disclaimer text={translate(locales.disclaimer)} />
    </ScreenLayout>
  )
}

export default localised(Recover)
