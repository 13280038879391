import { Fragment, h } from 'preact'
import { LockIcon } from './assets/lockIcon'
import style from './style.scss'
import { SafariIcon } from './assets/safariIcon'
import { FirefoxIcon } from './assets/firefoxIcon'
import { SamsungIcon } from './assets/samsungIcon'
import { ChromeIcon } from './assets/chromeIcon'
import { SystemInfo } from '~utils/detectSystem'
import { AppleIcon } from './assets/appleIcon'
import { WindowsIcon } from './assets/windowsIcon'
import { SettingsWindowsIcon } from './assets/settingsWindowsIcon'
import { SettingsFirefoxIcon } from './assets/settingsFirefoxIcon'
import { EdgeIcon } from './assets/edgeIcon'
type Browsers = {
  isSafari: boolean
  isFirefox: boolean
  isSamsung: boolean
  isChrome: boolean
  isEdge: boolean
  browser: SystemInfo
}
export const getBrowserIcon = ({
  isSafari,
  isFirefox,
  isSamsung,
  isChrome,
  isEdge,
  browser,
}: Browsers) => {
  if (isSafari) {
    return (
      <Fragment>
        <span className={style.strong}>Safari</span>
        <span className={style.icon}>
          <SafariIcon />
        </span>
      </Fragment>
    )
  }
  if (isFirefox) {
    return (
      <Fragment>
        <span className={style.strong}>Firefox</span>
        <span className={style.icon}>
          <FirefoxIcon />
        </span>
      </Fragment>
    )
  }
  if (isSamsung) {
    return (
      <Fragment>
        <span className={style.strong}>Samsung Internet</span>
        <span className={style.icon}>
          <SamsungIcon />
        </span>
      </Fragment>
    )
  }

  if (isChrome) {
    return (
      <Fragment>
        <span className={style.strong}>Chrome</span>
        <span className={style.icon}>
          <ChromeIcon />
        </span>
      </Fragment>
    )
  }

  if (isEdge) {
    return (
      <Fragment>
        <span className={style.strong}>Edge</span>
        <span className={style.icon}>
          <EdgeIcon />
        </span>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <span className={style.strong}>{browser.name}</span>
    </Fragment>
  )
}

export const getLockIcon = () => (
  <span className={style['icon-no-margin']}>
    <LockIcon />
  </span>
)

export const getAppleIcon = () => (
  <span className={style['icon-no-margin']}>
    <AppleIcon />
  </span>
)

export const getWindowsIcon = () => (
  <span className={style['icon-no-margin']}>
    <WindowsIcon />
  </span>
)

export const getWindowsSettingsIcon = () => (
  <span className={style['icon-no-margin']}>
    <SettingsWindowsIcon />
  </span>
)

export const getFirefoxSettingsIcon = () => (
  <span className={style['icon-no-margin']}>
    <SettingsFirefoxIcon />
  </span>
)
